import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import qs from 'qs';

import CsvFileList from './CsvFileList';

const CsvFileListContainer = props => {
  const { listId } = useParams();

  const {
    isLoading,
    isFetching,
    data: rows = [],
    refetch: refetchRows,
  } = useQuery(
    ['csvFiles', listId],
    () => axios.get(`/records/files/${listId}`),
    {
      select: data => data.filter(row => row.fileName),
    },
  );

  const {
    mutate: updateNotificationState,
    isLoading: isNotificationStateLoading,
  } = useMutation(
    e =>
      axios.get(
        `/records/sendNotificationAfterImport?${qs.stringify({
          importId: e.target.name,
          shouldSendNotificationAfterImport: !e.target.checked,
        })}`,
      ),
    {
      onSuccess: () => {
        refetchRows();
      },
    },
  );

  return (
    <div style={{ backgroundColor: 'white' }}>
      <CsvFileList
        rows={rows}
        onNotificationStateChange={updateNotificationState}
        isLoading={isLoading || isFetching || isNotificationStateLoading}
      />
    </div>
  );
};

export default CsvFileListContainer;
