import axios from 'axios';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useState, useEffect, useRef } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import Spinner from '../../components/Spinner';
import PageHeader from '../../components/PageHeader';
import CsvFileListContainer from './CsvFileListContainer';
import UploadContactsContainer from './UploadContactsContainer';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const ListView = () => {
  const history = useHistory();
  const { listId } = useParams();
  const [chartDetails, setChartDetails] = useState({});
  const chartRef = useRef(null);
  let chartInstance = useRef(null);

  const { data: list } = useQuery(
    ['list', listId],
    () => axios.get(`/partner-lists/${listId}`),
    {
      enabled: !!listId,
      onError: () => {
        history.push('/404');
      },
      onSuccess: res => {
        setChartDetails({
          hourly_data: res.hourly_data,
          current_db_time: res.current_db_time,
        });
      },
    },
  );

  useEffect(() => {
    if (chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      const chartData = {
        labels: chartDetails.hourly_data.map(item => {
          const date = new Date(item.date_created);
          return date.getHours() === 0 ? date.toLocaleDateString() : '';
        }),
        datasets: [
          {
            label: 'Hourly Records',
            data: chartDetails.hourly_data.map(item => item.hourly_records),
            borderColor: '#E00001',
            backgroundColor: 'rgba(224, 0, 1, 0.2)',
            borderWidth: 2,
            tension: 0.4,
            pointRadius: 0, // Changed: remove visible points
            pointHoverRadius: 0, // Changed: no hover radius
          },
        ],
      };

      const chartOptions = {
        responsive: true,
        interaction: {
          mode: 'index', // Changed: show tooltip on index
          intersect: false, // Changed: no need to intersect exactly
        },
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'List Size History',
          },
          tooltip: {
            mode: 'index', // Changed: tooltip mode
            intersect: false, // Changed: no intersect needed
            callbacks: {
              label: function (context) {
                const dataIndex = context.dataIndex;
                const dateCreated =
                  chartDetails.hourly_data[dataIndex]?.date_created;
                let cleanedDate = dateCreated.split(' ')[0];
                let cleanedTime = dateCreated
                  .split(' ')[1]
                  .split(':')
                  .slice(0, 2)
                  .join(':');
                return [
                  `Date: ${cleanedDate + ' ' + cleanedTime}`,
                  `Records: ${context.raw}`,
                ];
              },
            },
            displayColors: false,
          },
        },
        scales: {
          x: {
            type: 'category',
            ticks: {
              maxRotation: 45,
              autoSkip: false,
              callback: function (value, index) {
                const date = new Date(this.getLabelForValue(index));
                return date.getHours() === 0 ? date.toLocaleDateString() : '';
              },
            },
          },
          y: {
            title: {
              display: true,
              text: 'Hourly Records',
            },
            // beginAtZero: true,
            // min: 0,
          },
        },
        layout: {
          padding: 10,
        },
        backgroundColor: 'white',
      };

      chartInstance.current = new ChartJS(chartRef.current, {
        type: 'line',
        data: chartData,
        options: chartOptions,
      });
    }
  }, [chartDetails]);

  if (listId && !list) {
    return <Spinner fixed />;
  }

  return (
    <>
      <canvas
        ref={chartRef}
        height="80%"
        style={{
          backgroundColor: '#FFFFFF',
          // transform: 'scale(0.90, 0.80)',
        }}
      ></canvas>
      <br />
      <PageHeader
        title={`Add Contacts to ${list.name}`}
        buttons={[
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => history.push('/list-manager')}
          >
            Finish
          </Button>,
          <UploadContactsContainer />,
        ]}
      />
      <CsvFileListContainer chartDetails={chartDetails} />
    </>
  );
};

export default ListView;
