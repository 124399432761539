/* eslint-disable array-callback-return */
// React & axios
import React, { useEffect, useRef, useMemo, useState } from 'react';
import axios from 'axios';
// jQuery and datatables
import $ from 'jquery';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
import 'datatables.net-bs4';
// MUI components
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
// components
import ContactListRowAction from '../../components/ContactListRowAction';
import CallBack from './callback';
import '../../dtButtons.css';
import Chart from 'chart.js/auto';
//icons
import EditIcon from '../../assets/icons/edit.svg';
import DeleteIcon from '../../assets/icons/delete.svg';
import EmailAt from '../../assets/icons/emailAt.svg';
import Preview from '../../assets/icons/preview.svg';

const getYesterdayDate = () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  return yesterday.toISOString().split('T')[0];
};

const getTodayDate = () => {
  return new Date().toISOString().split('T')[0];
};

const getParamsFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};

  params.draw = parseInt(urlParams.get('draw')) || 1;
  params.start = parseInt(urlParams.get('start')) || 0;
  params.length = parseInt(urlParams.get('length')) || 25; // Default to 25

  params.dateStart = urlParams.get('dateStart') || getYesterdayDate();
  params.dateEnd = urlParams.get('dateEnd') || getTodayDate();

  const order = urlParams.get('order');
  if (order) {
    params.order = JSON.parse(order);
  }

  const search = urlParams.get('search');
  if (search) {
    params.search = JSON.parse(search);
  }

  return params;
};

const renderStatus = row => {
  const total = row.total ? row.total : 0;
  const processed = row.processed ? row.processed : 0;
  const failed = row.failed ? row.failed : 0;
  const exist = row.exist ? row.exist : 0;

  if (total === 0) {
    return 'Queued for Processing';
  } else if (total > 0 && processed + exist === 0) {
    return 'Failed';
  } else if (total > 0 && total - 10 <= processed + failed + exist) {
    return 'Completed';
  } else if (total > 0 && total - 10 > processed + failed + exist) {
    return 'Upload in Progress';
  }

  return '';
};

const fetchData = async params => {
  const urlParams = getParamsFromURL();

  const mergedParams = { ...urlParams, ...params };
  const { columns, ...paramsWithoutColumns } = mergedParams;
  const serializedParams = {};

  for (let key in paramsWithoutColumns) {
    if (paramsWithoutColumns.hasOwnProperty(key)) {
      if (typeof paramsWithoutColumns[key] === 'object') {
        serializedParams[key] = JSON.stringify(paramsWithoutColumns[key]);
      } else {
        serializedParams[key] = paramsWithoutColumns[key];
      }
    }
  }

  // Update the URL with the correct query parameters
  const queryString = new URLSearchParams(serializedParams).toString();
  const newUrl = `${window.location.pathname}?${queryString}`;
  window.history.replaceState(null, '', newUrl);
  if (mergedParams?.order[0]?.column && mergedParams?.order[0]?.column > 4) {
    mergedParams.order[0].column -= 1;
  }

  const response = await axios.post(
    `/triggers/datatables?datatables=management`,
    mergedParams,
  );
  return response;
};

const ListCollection = () => {
  const [listDel, setListDel] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [delPass, setDelPass] = useState(false);
  const [delFail, setDelFail] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [contactID, setContactID] = useState(0);

  // datatables render
  const tableRef = useRef(null);
  const paramsFromURL = useMemo(() => getParamsFromURL(), []);

  const handleClose = () => {
    setDelPass(false);
    setDelFail(false);
  };

  const handleDelete = async id => {
    setListDel(false);
    axios
      .put(`/partner-lists/${id}/archive`)
      .then(pass => {
        setDelPass(true);
      })
      .catch(err => {
        console.error(err);
        setDelFail(true);
      })
      .finally(() => {
        setDeleteId(0);
        setTimeout(() => {
          setDelFail(false);
          setDelPass(false);
          window.location.reload();
        }, 4000);
      });
  };

  const renderActions = data => {
    return `
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <a href="/list-manager/${data.id}/contacts" title="Upload Contacts" class="edit-button" style="padding: 5px;">
          <img src="${EmailAt}" alt="Upload Contacts" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
        </a>
        <a title="Add/Remove Contact From List Via API" class="contacts-button" style="padding: 5px;">
          <img src="${Preview}" alt="Add/Remove Contact" style="cursor: pointer; width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
        </a>
        <a href="/list-manager/${data.id}/edit" title="Edit" class="edit-button" style="padding: 5px;">
          <img src="${EditIcon}" alt="Edit" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
        </a>
        <a class="delete-button" style="cursor: pointer; padding: 5px;">
          <img src="${DeleteIcon}" alt="Delete" style="width:40px; height:40px; background-color:#BABFD3; padding:4px; border-radius:4px;"/>
        </a>
      </div>
    `;
  };

  const columns = useMemo(
    () => [
      { title: 'ID', data: 'id' },
      { title: 'Name', data: 'name', width: '290px' },
      { title: 'Description', data: 'description' },
      {
        title: 'Trigger',
        data: 'trigger_name',
        width: '200px',
        render: function (data, type, row) {
          return row.trigger_id
            ? `<a href='/trigger-manager/${row.trigger_id}/edit'>${data}</a>`
            : data;
        },
      },
      {
        title: 'Records',
        data: 'list_count',
        width: '200px',
        className: 'text-center',
      },
      {
        title: 'List Size Over 7 Days',
        data: 'hourly_data',
        orderable: false,
        width: '125px',
        render: function (data, type, row) {
          const chartId = `chart-${row.id}`;
          return `<div class="chart-details" style="width:125px; height:100px;"><canvas id="${chartId}"></canvas></div>`;
        },
      },
      {
        title: 'Status',
        data: null,
        width: '200px',
        render: function (data) {
          return renderStatus(data);
        },
      },
      {
        title: 'Date Created',
        data: 'date_created',
        width: '200px',
        render: function (data) {
          if (!data) return ''; // Handle case if data is null or undefined

          const date = new Date(data);

          // Extract month, day, year, hours, minutes, and seconds
          const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is 0-indexed
          const day = String(date.getDate()).padStart(2, '0');
          const year = date.getFullYear();
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');

          return `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
        },
      },
      {
        title: 'Actions',
        data: null,
        width: '92px',
        className: 'text-center',
        orderable: false,
        render: function (data) {
          return `<div>${renderActions(data)}</div>`;
        },
      },
    ],
    [],
  );

  useEffect(() => {
    if (!$.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable({
        serverSide: true,
        processing: true,
        columns: columns,
        dom: '<"d-flex align-items-center dt-controls"lf>rtip',
        responsive: true,
        scrollX: true,
        order: paramsFromURL.order || [[0, 'asc']],
        pageLength: paramsFromURL.length || 25, // Default to 25 entries per page
        ajax: (data, callback, settings) => {
          fetchData(data)
            .then(result => {
              const recordsFiltered = result.recordsFiltered || 0;
              const recordsTotal = result.recordsTotal || 0;

              callback({
                draw: data.draw,
                recordsTotal: recordsTotal,
                recordsFiltered: recordsFiltered,
                data: result.data,
              });
            })
            .catch(error => {
              console.error('Error fetching data: ', error);
              callback({
                draw: data.draw,
                recordsTotal: 0,
                recordsFiltered: 0,
                data: [],
              });
            });
        },
        language: {
          infoFiltered: '',
        },
        initComplete: function () {
          const api = this.api();
          applyCustomStyles(api);
          attachEventListeners(api);

          // Apply search value from URL to the search input
          if (paramsFromURL.search && paramsFromURL.search.value) {
            api.search(paramsFromURL.search.value).draw();
          }
        },
        drawCallback: function (settings) {
          const api = this.api();

          api
            .rows({ page: 'current' })
            .every(function (rowIdx, tableLoop, rowLoop) {
              const rowData = this.data();
              const chartId = `chart-${rowData.id}`; // Match chart ID in render
              const canvas = document.getElementById(chartId);

              if (canvas && rowData.hourly_data) {
                // Ensure 28 data points in hourly_data
                const expectedLength = 28;
                const currentLength = rowData.hourly_data.length;

                if (currentLength < expectedLength) {
                  const firstEntry = rowData.hourly_data[0];
                  const firstDate = new Date(
                    firstEntry?.date_created || new Date(),
                  );
                  const firstValue = firstEntry?.hourly_records || 0;

                  const missingData = [];

                  for (let i = 0; i < expectedLength - currentLength; i++) {
                    const missingDate = new Date(firstDate);
                    missingDate.setHours(
                      firstDate.getHours() - (i + 1) * 6,
                      0,
                      0,
                      0,
                    ); // Subtract 6 hours for each missing entry

                    const formattedDate = missingDate
                      .toISOString()
                      .slice(0, 19)
                      .replace('T', ' ');

                    missingData.unshift({
                      date_created: formattedDate,
                      hourly_records: firstValue, // Use the first value to fill
                    });
                  }

                  rowData.hourly_data = [
                    ...missingData,
                    ...rowData.hourly_data,
                  ]; // Prepend missing data
                }

                // Add the current timestamp and list_count as the last data point
                // const now = new Date();
                // const formattedNow = now
                //   .toISOString()
                //   .slice(0, 19)
                //   .replace('T', ' ');

                const formattedNow = rowData.current_db_time;
                const recordCount = parseInt(
                  rowData.list_count.split(',').join(''),
                  10,
                );

                rowData.hourly_data.push({
                  date_created: formattedNow,
                  hourly_records: recordCount,
                });

                // Extract X and Y axis data from hourly_data
                const labels = rowData.hourly_data.map(entry =>
                  entry.date_created.slice(0, 19),
                ); // Trim timestamp
                const dataPoints = rowData.hourly_data.map(
                  entry => entry.hourly_records,
                );

                // Calculate min and max values for the Y-axis
                const maxValue =
                  Math.max(...dataPoints) < 1000
                    ? Math.max(...dataPoints) * 3
                    : Math.max(...dataPoints) + 1; // Conditional max value
                const minValue = Math.min(...dataPoints) - 1; // Add buffer below

                if (maxValue === 0) {
                  canvas.style.display = 'none'; // Hide the canvas if maxValue is 0
                  return;
                }

                // Destroy any existing chart on the canvas
                Chart.getChart(chartId)?.destroy();

                // Initialize a new Chart.js instance
                new Chart(canvas, {
                  type: 'line', // Use line chart
                  data: {
                    labels: labels, // X-axis labels (timestamps)
                    datasets: [
                      {
                        label: 'Hourly Records',
                        data: dataPoints, // Y-axis data
                        borderColor: '#E00001', // Line color
                        backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
                        tension: 0.4, // Smooth curves for the line
                        borderWidth: 2, // Thickness of the line
                        pointRadius: 0, // Disable point display (no bubbles)
                      },
                    ],
                  },
                  options: {
                    responsive: true,
                    maintainAspectRatio: false, // Allow the chart to fit within the table cell
                    plugins: {
                      legend: { display: false }, // Hide the legend
                      tooltip: {
                        enabled: true, // Ensure tooltips are enabled
                        callbacks: {
                          label: function (context) {
                            const value = context.raw; // Y-axis value
                            return `${value} records`;
                          },
                        },
                        displayColors: false, // Removes the color box from the tooltip
                      },
                    },
                    interaction: {
                      mode: 'nearest', // Tooltip activation mode
                      axis: 'x', // Trigger tooltips on the X-axis
                      intersect: false, // Show tooltips even when not directly over a point
                    },
                    scales: {
                      x: {
                        display: false, // Completely hide the X-axis
                      },
                      y: {
                        display: false, // Completely hide the Y-axis
                        suggestedMin: minValue, // Dynamically set minimum value
                        suggestedMax: maxValue, // Dynamically set maximum value
                      },
                    },
                    layout: {
                      padding: {
                        top: 10,
                        bottom: 10,
                        right: 5,
                      },
                    },
                    elements: {
                      line: {
                        tension: 0.4, // Smooth curves
                        borderWidth: 2, // Line thickness
                      },
                    },
                  },
                });
              }
            });

          applyCustomStyles(api);
          attachEventListeners(api);

          const draw = settings.iDraw || api.context[0]._iDraw;
          const start = settings._iDisplayStart;
          const length = settings._iDisplayLength;
          const order = api.order();
          const search = api.search(); // Capture the search value

          // Serialize parameters for the URL
          const queryString = new URLSearchParams({
            draw: draw,
            start: start,
            length: length,
            order: JSON.stringify(order),
            search: JSON.stringify({ value: search, regex: false }), // Include the search value
          }).toString();

          // Update the URL with the new query string
          const newUrl = `${window.location.pathname}?${queryString}`;
          window.history.replaceState(null, '', newUrl);
        },
      });
    }

    function applyCustomStyles(api) {
      // Continue applying other custom styles
      $(api.table().container())
        .find('label, input, select, th, td, .dt-info')
        .css({
          'font-size': '14px',
          'font-weight': 'bold',
        });
      $(api.table().container()).find('label').css({
        padding: '5px',
      });
      $(api.table().container()).find('input, select').css({
        margin: '10px',
      });
      $(api.table().container()).find('thead tr th').css({
        'padding-left': '10px',
        'text-align': 'left',
        'border-bottom': '2px solid #dee2e6',
        'border-right': '1px solid #dee2e6',
      });
      $(api.table().container()).find('tfoot tr th').css({
        'border-bottom': '2px solid #dee2e6',
      });
      $(api.table().container()).find('.dt-info').css({
        'padding-left': '10px',
      });
      $(api.table().container()).find('tbody tr td').css({
        'padding-left': '10px',
        'border-bottom': '1px solid #dee2e6',
        'border-right': '1px solid #dee2e6',
        'white-space': 'normal', // Change to normal to wrap text
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
      });
      $(api.table().container())
        .find('tbody tr td:last-child, thead tr th:last-child')
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th, .DTFC_LeftWrapper table td, .DTFC_Cloned th, .DTFC_Cloned td',
        )
        .css({
          'border-right': '1px solid #dee2e6',
          'background-color': 'inherit',
        });
      $(api.table().container())
        .find(
          '.DTFC_LeftWrapper table th:last-child, .DTFC_LeftWrapper table td:last-child, .DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child',
        )
        .css({
          'border-right': 'none',
        });
      $(api.table().container())
        .find('.DTFC_Cloned th:last-child, .DTFC_Cloned td:last-child')
        .css({
          'box-shadow': '10px 0 5px -2px rgba(0, 0, 0, 0.3)',
        });

      $(api.table().container()).find('ul.pagination').css({
        display: 'flex', // Use flexbox to align items horizontally
        justifyContent: 'center', // Center align pagination controls
        padding: '0', // Remove default padding
        marginTop: '10px', // Add some spacing above the pagination controls
        listStyle: 'none', // Remove default list styling
      });

      $(api.table().container()).find('ul.pagination li').css({
        display: 'inline',
        margin: '0 2px', // Add spacing between pagination items
      });

      $(api.table().container()).find('ul.pagination li a').css({
        display: 'inline-block', // Ensure links are inline
        padding: '5px 10px', // Add padding for clickable area
        border: '1px solid #ddd', // Border for pagination items
        borderRadius: '5px', // Rounded corners for pagination items
        textDecoration: 'none', // Remove underline from links
        color: '#333', // Text color
      });

      $(api.table().container()).find('ul.pagination li.active a').css({
        backgroundColor: '#E00001', // Highlight background color for the active page
        color: 'white', // Text color for active page
      });

      $(api.table().container()).find('ul.pagination li a:hover').css({
        backgroundColor: '#f1f1f1', // Background color on hover
        color: '#333', // Text color on hover
      });

      // Apply Flexbox styling to the parent container of "entries per page" and "Search"
      $(api.table().container()).find('.dt-controls').css({
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%', // Ensure it fills the entire width of the container
      });

      // Increase size for all inputs with ids matching dt-search-*
      $(api.table().container()).find('input[id^="dt-search-"]').css({
        height: '2.5rem',
        width: '15rem',
      });

      // Increase size for all selects with ids matching dt-length-*
      $(api.table().container()).find('select[id^="dt-length-"]').css({
        height: '2.5rem',
        width: '3rem',
      });

      // Apply flexbox to div.dt-length
      $(api.table().container()).find('div.dt-length').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('div.dt-search').css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      });

      $(api.table().container()).find('.buttons-html5, .buttons-print').css({
        'background-color': '#E00001',
        'border-width': '2px',
        'border-radius': '10px',
        'border-color': '#E00001',
        color: 'white',
        padding: '10px',
        cursor: 'pointer', // Add this line to change the cursor on hover
      });
    }

    function attachEventListeners(api) {
      $('.delete-button')
        .off('click')
        .on('click', function () {
          const row = $(this).closest('tr');
          const rowData = api.row(row).data();

          setListDel(true);
          setDeleteId(rowData.id);
        });

      $('.contacts-button')
        .off('click')
        .on('click', function () {
          const row = $(this).closest('tr');
          const rowData = api.row(row).data();

          setContactModal(true);
          setContactID(rowData.id);
        });

      // $('.chart-details')
      // .off('click')
      // .on('click', function () {
      //   const row = $(this).closest('tr');
      //   const rowData = api.row(row).data();

      //   if (!rowData.hourly_data || rowData.hourly_data.length === 0) {
      //     return;
      //   }

      //   // Ensure 28 data points with 0/6/12/18 hour convention
      //   const expectedLength = 28;
      //   const currentLength = rowData.hourly_data.length;

      //   if (currentLength < expectedLength) {
      //     const firstDate = new Date(rowData.hourly_data[0]?.date_created || new Date());

      //     const missingData = [];

      //     for (let i = 0; i < expectedLength - currentLength; i++) {
      //       const missingDate = new Date(firstDate);
      //       missingDate.setHours(
      //         Math.floor((missingDate.getHours() - (i + 1) * 6) / 6) * 6,
      //         0,
      //         0,
      //         0
      //       ); // Align to 0/6/12/18 hours

      //       const formattedDate = missingDate.toISOString().slice(0, 19).replace('T', ' ');

      //       missingData.unshift({
      //         date_created: formattedDate,
      //         hourly_records: 0,
      //       });
      //     }

      //     rowData.hourly_data = [...missingData, ...rowData.hourly_data];
      //   }

      //   // Construct the Chart.js configuration
      //   const labels = rowData.hourly_data.map((entry) => entry.date_created);
      //   const dataPoints = rowData.hourly_data.map((entry) => entry.hourly_records);

      //   const chartConfig = {
      //     type: 'line', // Use line chart
      //     data: {
      //       labels: labels, // X-axis labels (timestamps)
      //       datasets: [
      //         {
      //           label: 'Hourly Records',
      //           data: dataPoints, // Y-axis data
      //           borderColor: '#E00001', // Line color
      //           backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
      //           tension: 0.4, // Smooth curves for the line
      //           borderWidth: 2, // Thickness of the line
      //           pointRadius: 0, // Disable point display (no bubbles)
      //         },
      //       ],
      //     },
      //     options: {
      //       responsive: true,
      //       maintainAspectRatio: false,
      //       plugins: {
      //         legend: { display: false }, // Hide the legend
      //         tooltip: {
      //           enabled: true,
      //           callbacks: {
      //             label: function (context) {
      //               const timestamp = context.label; // X-axis label (timestamp)
      //               const value = context.raw; // Y-axis value
      //               return [`${value} records`, `at ${timestamp}`];
      //             },
      //           },
      //           displayColors: false, // Removes the color box from the tooltip
      //         },
      //       },
      //       interaction: {
      //         mode: 'nearest', // Tooltip activation mode
      //         axis: 'x', // Trigger tooltips on the X-axis
      //         intersect: false, // Show tooltips even when not directly over a point
      //       },
      //       scales: {
      //         x: {
      //           display: true, // Display X-axis for the full chart view
      //         },
      //         y: {
      //           display: true, // Display Y-axis for the full chart view
      //         },
      //       },
      //     },
      //   };

      //   // Serialize the Chart.js configuration into a URL-safe string
      //   const chartUrl = `https://quickchart.io/chart?c=${encodeURIComponent(
      //     JSON.stringify(chartConfig)
      //   )}`;

      //   // Open the generated QuickChart.io URL in a new tab
      //   window.open(chartUrl, '_blank');
      // });
    }

    return () => {
      if ($.fn.DataTable.isDataTable(tableRef.current)) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        $(tableRef.current).DataTable().destroy();
      }
    };
  }, [columns, paramsFromURL]);

  return (
    <>
      <Snackbar
        open={delPass}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#4caf50',
          }}
          message={<Typography>Successfully Deleted List</Typography>}
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      <Snackbar
        open={delFail}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <SnackbarContent
          style={{
            backgroundColor: '#E00001',
          }}
          message={<Typography>Could not Delete List</Typography>}
          action={<CloseIcon fontSize="small" onClick={handleClose} />}
        />
      </Snackbar>
      {listDel ? (
        <CallBack id={deleteId} cbNo={setListDel} cbYes={handleDelete} />
      ) : null}
      {contactModal ? (
        <ContactListRowAction
          buttonLabel="Add/Remove Contact From List Via API"
          entityLabel={contactID}
          contactModal={contactModal}
          setContactModal={setContactModal}
        />
      ) : null}
      <Box>
        <table
          ref={tableRef}
          className="display"
          style={{ width: '100%' }}
        ></table>
      </Box>
    </>
  );
};

export default ListCollection;
